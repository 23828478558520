import parse from "html-react-parser"
import { titleCase, changeDateFormat } from "../../../utils/helpers.js"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { FaBriefcase } from "react-icons/fa"

const Preview = ({
  formik,
  screeningQuestions,
  jobDescription,
  currentStep,
  setCurrentStep,
}) => {
  const inputDateStr = new Date(formik.values.application_deadline)
  const inputDate = new Date(inputDateStr)
  const day = inputDate.getDate()
  const month = inputDate.getMonth() + 1
  const year = inputDate.getFullYear()

  const outputDateStr = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year.toString()}`

  return (
    <div className="py-3 text-black">
      <div className="space-y-6">
        {/* job title, company, location, employment type */}
        <div>
          <p className="text-2xl font-semibold">{`${formik.values.job_title} at ${formik.values.company_name}`}</p>
          <p className="text-sm text-gray-400">{`${formik.values.job_city}, ${formik.values.job_country} (${formik.values.employment_type})`}</p>
        </div>

        {/* contract type */}
        <div>
          <span className="flex items-center">
            <FaBriefcase size={24} className="mr-2" />
            <p>{titleCase(formik.values.contract_type)}</p>
          </span>
        </div>

        {/* job description */}
        {jobDescription && (
          <div className="list-disc">{parse(jobDescription)}</div>
        )}

        {/* salary range */}
        {formik.values.min_salary_range && formik.values.max_salary_range && (
          <div>
            <p className="text-base underline font-bold">Salary Range</p>
            <p>{`${formik.values.min_salary_range} - ${formik.values.max_salary_range} ${formik.values.currency}`}</p>
          </div>
        )}

        {/* application deadline */}
        <div>
          <p className="text-base underline font-bold">Application Deadline</p>
          <p>{outputDateStr}</p>
        </div>

        {/* screening questions */}
        {screeningQuestions.length > 0 && (
          <div>
            <span className="text-base underline font-bold">
              Additional Questions
            </span>
            {screeningQuestions.map((question, index) => (
              <div key={index} className="mb-2">
                {question.group === "Tool or Technology" && (
                  <p>{`How many years of experience do you have using ${question.question}?`}</p>
                )}

                {question.group === "Language" && (
                  <p>{`What is your level of proficiency in ${question.question}?`}</p>
                )}

                {question.group === "Industry" && (
                  <p>{`How many years of ${question.question} experience do you currently have?`}</p>
                )}

                {question.group === "Custom" && <p>{question.question}</p>}

                <p>Ideal Answer: {titleCase(question.answer)}</p>
                <p>
                  Must-have Qualification: {question.mustHave ? "Yes" : "No"}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setCurrentStep(currentStep - 1)
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={e => {
            formik.handleSubmit(e)
          }}
        >
          SUBMIT
        </Button>
      </Box>
    </div>
  )
}

export default Preview
