import { useState, useEffect } from "react"
import AdminHeader from "../components/AdminHeader"
import "react-calendar/dist/Calendar.css"
import { useNavigate, useLocation } from "react-router-dom"
import AdminLayout from "../components/AdminLayout"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import axios from "../api/axios"
import { FaUser, FaCalendarAlt } from "react-icons/fa"
import { timestampToYYYYMMDD, titleCase } from "../utils/helpers"
import { Helmet } from "react-helmet-async"

const AdminDashboard = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const [applicants, setApplicants] = useState()

  const [query, setQuery] = useState("")

  const [data, setData] = useState()
  const [status, setStatus] = useState()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const getData = async () => {
      const res = await axiosPrivate
        .get(`applications/${auth?.companyId}`, request)
        .catch(async err => {
          console.error(err.response)
        })
      if (res?.data?.data) {
        isMounted && setData(res.data.data)
      }
    }

    const getStatus = async () => {
      const res = await axiosPrivate
        .get(`applications/status/${auth?.companyId}`, request)
        .catch(async err => {
          console.error(err.response)
        })
      if (res?.data?.data) {
        isMounted && setStatus(res.data.data)
      }
    }

    const getNoOfApplicants = async () => {
      const res = await axiosPrivate
        .get(`applications/no-of-applicants/${auth?.companyId}`, request)

        .catch(async err => {
          console.error(err.response)
        })
      if (res?.data?.data) {
        isMounted && setApplicants(res.data.data)
      }
    }

    getData()
    getStatus()
    getNoOfApplicants()

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [auth?.accessToken, auth?.companyId, axiosPrivate]);


  const percent = (item, total) => {
    const result = (item / total) * 100;
    return `${result.toFixed(2)}%`;
  };

  const search = array => {
    return array.filter(
      item =>
        item?.applicant?.first_name?.includes(query) ||
        item?.applicant?.last_name?.includes(query) ||
        item?.job?.job_title?.includes(query)
    )
  }

  console.log("datering", { data })

  return (
    <AdminLayout page="dashboard">
      <Helmet>
        <title>Admin Dashboard | Edge</title>
      </Helmet>

      <AdminHeader page="admin-dashboard" setQuery={setQuery} />

      <div className="py-5 sm:px-8 px-4 cursor-default">
        <span className="text-2xl text-black">
          {" "}
          <h2 className="text-gray-700 font-bold">
            Welcome{" "}
            <span className="text-blue-600">{titleCase(auth?.firstName)}</span>
          </h2>
        </span>
        <div className="mt-6 flex w-full justify-between space-x-4">
          <div className="sm:w-2/3 w-full flex space-x-4">
            <div className="bg-white rounded-xl w-1/2 py-6 px-4">
              <p className="text-gray-400 text-xs font-semibold">
                APPLICATIONS
              </p>
              {status?.applied ? (
                <p className="text-black text-3xl font-semibold">
                  {status?.applied}
                </p>
              ) : (
                <p className="text-black text-3xl font-semibold">--</p>
              )}
            </div>
            <div className="bg-white rounded-xl w-1/2 py-6 px-4">
              <p className="text-gray-400 text-xs font-semibold">SHORTLISTED</p>
              {status?.shortlisted ? (
                <p className="text-black text-3xl font-semibold">
                  {status?.shortlisted}
                </p>
              ) : (
                <p className="text-black text-3xl font-semibold">--</p>
              )}
            </div>
          </div>

          <div className="hidden sm:block bg-white rounded-xl w-1/3 py-6 px-4">
            <p className="text-gray-400 text-xs font-semibold">ON-HOLD</p>
            {status?.onHold ? (
              <p className="text-black text-3xl font-semibold">
                {status?.onHold}
              </p>
            ) : (
              <p className="text-black text-3xl font-semibold">--</p>
            )}
          </div>
          </div>
          </div>

        <div className="mt-6 flex sm:flex-row flex-col w-full justify-between sm:space-x-4 sm:space-y-0 space-y-4">
          <div className="bg-white rounded-xl sm:w-2/3 w-full py-6 px-2.5">
            <p className="font-semibold text-black">Jobs Overview</p>

            <table className="w-full text-black mt-5">
              <thead className="w-full">
                <tr className="w-full">
                  <th className="w-2/3">Job Title</th>
                  <th className="w-1/3">Applications</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {applicants?.length > 0 &&
                  applicants.map((item, index) => (
                    <tr
                      className="w-full cursor-pointer hover:bg-gray-100"
                      title="View Applications"
                      key={index}
                      onClick={() => {
                        navigate(`/jobs/${item.id}/applications`)
                      }}
                    >
                      <td className="w-2/4 text-sm lg:text-sm font-medium">
                        <div className="flex items-center">
                          <div className="mr-2">
                            {titleCase(item.jobTitle)} at{" "}
                            <span className="text-blue-600">
                              {titleCase(item.company)}
                            </span>
                          </div>
                        </div>
                        <div className="text-sm text-gray-500">
                          <FaCalendarAlt className="inline-block mr-1" />
                          {timestampToYYYYMMDD(item.created_at)}
                        </div>
                      </td>
                      <td className="w-1/4 text-purple-600 text-xs lg:text-sm font-medium">
                        {item.noOfApplicants}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="sm:w-1/3 w-full flex flex-col sm:space-y-6">
            <div className="hidden sm:block bg-white rounded-xl w-full py-6 px-2.5">
              <p className="text-md font-semibold text-black mb-2.5">
                Acquisitions
              </p>

              <div className="w-full flex mb-6">
                <div
                  className="h-1 bg-red-500 rounded-l-md"
                  style={{
                    width: `${percent(status?.rejected, status?.total)}`,
                  }}
                ></div>
                <div
                  className="h-1 bg-orange-500"
                  style={{
                    width: `${percent(status?.onHold, status?.total)}`,
                  }}
                ></div>
                <div
                  className="h-1 bg-blue-500"
                  style={{
                    width: `${percent(status?.shortlisted, status?.total)}`,
                  }}
                ></div>
                <div
                  className="h-1 bg-purple-500 rounded-r-md"
                  style={{
                    width: `${percent(status?.applied, status?.total)}`,
                  }}
                ></div>
                <div
                  className="h-1 bg-purple-500 rounded-r-md"
                  style={{
                    width: `${percent(status?.hrReview, status?.total)}`,
                  }}
                ></div>
                <div
                  className="h-1 bg-purple-500 rounded-r-md"
                  style={{
                    width: `${percent(status?.firstTechInterview, status?.total)}`,
                  }}
                ></div>
                <div
                  className="h-1 bg-purple-500 rounded-r-md"
                  style={{
                    width: `${percent(status?.secondTechInterview, status?.total)}`,
                  }}
                ></div>
                <div
                  className="h-1 bg-purple-500 rounded-r-md"
                  style={{
                    width: `${percent(status?.selected, status?.total)}`,
                  }}
                ></div>
                <div
                  className="h-1 bg-green-500 rounded-r-md"
                  style={{
                    width: `${percent(status?.OnBoarded, status?.total)}`,
                  }}
                ></div>
              </div>

              <div className="flex text-xs font-medium justify-between">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-purple-500 mr-1.5"></div>
                  <p className="hover:bg-gray-100 text-gray-400 cursor-pointer" title={status?.applied}>Applications</p>
                </div>
                <div className="text-black">
                  {percent(status?.applied, status?.total)}
                </div>
              </div>

              <div className="flex text-xs font-medium justify-between mt-2.5">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-blue-500 mr-1.5"></div>
                  <p className="hover:bg-gray-100 text-gray-400 cursor-pointer" title={status?.shortlisted}>Shortlisted</p>
                 
                </div>
                <div className="text-black">
                  {percent(status?.shortlisted, status?.total)}
                </div>
              </div>

              <div className="flex text-xs font-medium justify-between mt-2.5">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-orange-500 mr-1.5"></div>
                  <p className="hover:bg-gray-100 w-full text-gray-400 cursor-pointer" title={status?.onHold}>On-hold</p>
                </div>
                <div className="text-black">
                  {percent(status?.onHold, status?.total)}
                </div>
              </div>

              <div className="flex text-xs font-medium justify-between mt-2.5">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-red-500 mr-1.5"></div>
                  <p className="hover:bg-gray-100 text-gray-400 cursor-pointer" title={status?.rejected}>Rejected</p>
                 
                </div>
                <div className="text-black">
                  {percent(status?.rejected, status?.total)}
                </div>
              </div>

              <div className="flex text-xs font-medium justify-between mt-2.5">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-purple-200 mr-1.5"></div>
                  <p className="hover:bg-gray-100 text-gray-400 cursor-pointer" title={status?.hrReview}>Hr-Review</p>
                 
                </div>
                <div className="text-black">
                  {percent(status?.hrReview, status?.total)}
                </div>
              </div>

              <div className="flex text-xs font-medium justify-between mt-2.5">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-yellow-200 mr-1.5"></div>
                  <p className="hover:bg-gray-100 text-gray-400 cursor-pointer" title={status?.firstTechInterview}>Fisrt Technical Interview</p>
                 
                </div>
                <div className="text-black">
                  {percent(status?.firstTechInterview, status?.total)}
                </div>
              </div>

              <div className="flex text-xs font-medium justify-between mt-2.5">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-yellow-500 mr-1.5"></div>
                  <p className="hover:bg-gray-100 text-gray-400 cursor-pointer" title={status?.secondTechInterview}>Second Technical Interview</p>
                  
                </div> 
                <div className="text-black">
                  {percent(status?.secondTechInterview, status?.total)}
                </div>
              </div>

              <div className="flex text-xs font-medium justify-between mt-2.5">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-green-200 mr-1.5"></div>
                  <p className="hover:bg-gray-100 text-gray-400 cursor-pointer" title={status?.selected}>Selected</p>
                  
                </div>
                <div className="text-black">
                  {percent(status?.selected, status?.total)}
                </div>
              </div>

              <div className="flex text-xs font-medium justify-between mt-2.5">
                <div className="flex items-center">
                  <div className="h-2.5 w-5 rounded-md bg-green-500 mr-1.5" ></div>
                  <p className="hover:bg-gray-100 text-gray-400 cursor-pointer" title={status?.onBoarded}>On-Boarded</p>
                 
              </div>
                <div className="text-black">
                  {percent(status?.onBoarded, status?.total)}
                </div>
              </div>

            <div className="bg-white rounded-xl w-full py-4 px-2.5 cursor-default">
              <p className="text-md font-semibold text-black mb-2.5">
                New Applicants
              </p>
              {data
                ? search(data).map((item, index) => (
                    <div key={index} className="flex mt-2 mb-2.5 items-start">
                      <div className="w-12 h-12 xl:w-16 xl:h-16 aspect-square rounded-full bg-gray-300 mr-4 flex items-start relative">
                        {item?.applicant?.avatar ? (
                          <img
                            crossOrigin="anonymous"
                            src={`${process.env.REACT_APP_BASE_URL}storage/images/${item?.applicant?.avatar}`}
                            className="w-full h-full object-center rounded-full"
                            alt=""
                          />
                        ) : (
                          <FaUser
                            size={24}
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
                          />
                        )}
                      </div>
                      <div className="">
                        <p className="text-black text-sm lg:text-sm font-medium">{`${titleCase(
                          item?.applicant?.first_name
                        )} ${titleCase(item?.applicant?.last_name)}`}</p>
                        <p className="text-blue-400 text-xs lg:text-sm font-medium">
                          Applied for :
                          <span className="text-black ml-1">
                            {item?.job?.job_title} at{" "}
                            <span className="text-purple-600">
                              {item?.job?.company_name}
                            </span>
                          </span>
                        </p>
                        <p className="text-xs lg:text-sm font-medium">
                          <span className="text-blue-400">Date Applied :</span>

                          <span className="text-sm text-gray-500 ml-1">
                            <FaCalendarAlt className="inline-block mr-1" />
                            {timestampToYYYYMMDD(item?.createdAt)}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default AdminDashboard
