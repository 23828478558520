import Upload from "../components/Upload"
import AdminLayout from "../components/AdminLayout"
import BulkUploadHeader from "../components/BulkUploadHeader"
import { Helmet } from "react-helmet-async"

const BulkUpload = () => {
  return (
    <AdminLayout page="applicants/upload">
      <Helmet>
        <title>Applicant Management | Edge</title>
      </Helmet>

      <BulkUploadHeader page="bulk-upload" />
      <Upload />
    </AdminLayout>
  )
}

export default BulkUpload
