//import { useState } from "react";
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { updateOption, currency } from "../../../utils/data"
import Editor from "ckeditor5-custom-build/build/ckeditor"
import { CKEditor } from "@ckeditor/ckeditor5-react"

//import parse from "html-react-parser";

const About = ({
  formik,
  jobDescription,
  setJobDescription,
  currentStep,
  setCurrentStep,
}) => {
  const onEditorChange = (event, editor) => {
    const data = editor.getData()
    // setUserData({ ...userData, description: data })
    setJobDescription(data)
  }

  console.log({formik})
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiTextField-root": { mt: "1rem" },
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      }}
    >
      <div className="text-black list-decimal mx-auto w-full">
        <CKEditor
          editor={Editor}
          data={jobDescription}
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
            // editor.ui.view.editable.element.style.width = "500px"
          }}
          onChange={(event, editor) => {
            onEditorChange(event, editor)
          }}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />
      </div>

      {/**salary range */}
      <Box
        sx={{
          width: "100%",
          mt: "1rem",
          mx: "auto",
          justifyContent: "space-between",
          display: "flex",
          "& .MuiTextField-root": { mt: "1rem" },
          "& .MuiSvgIcon-root": {
            color: "#000000",
          },
        }}
      >
        <TextField
          // required
          variant="outlined"
          sx={{ width: "40%", pr: 1 }}
          size="small"
          id="currency"
          label="Select Currency"
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.currency}
          onChange={formik.handleChange("currency")}
          error={formik.touched.currency && Boolean(formik.errors.currency)}
          helperText={formik.touched.currency && formik.errors.currency}
          select
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {currency.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <Box sx={{ display: "flex" }}>
          <TextField
               // required
            variant="outlined"
            //fullWidth
            sx={{ width: "50%", pr: 1 }}
            size="small"
            id="min_salary_range"
            label="Minimun (Optional)"
            value={formik.values.min_salary_range}
            onChange={formik.handleChange("min_salary_range")}
            error={
              formik.touched.min_salary_range &&
              Boolean(formik.errors.min_salary_range)
            }
            helperText={
              formik.touched.min_salary_range && formik.errors.min_salary_range
            }
          />
          <TextField
            // required
            variant="outlined"
            //fullWidth
            sx={{ width: "50%" }}
            size="small"
            id="max_salary_range"
            label="Maximum (Optional)"
            value={formik.values.max_salary_range}
            onChange={formik.handleChange("max_salary_range")}
            error={
              formik.touched.max_salary_range &&
              Boolean(formik.errors.max_salary_range)
            }
            helperText={
              formik.touched.max_salary_range && formik.errors.max_salary_range
            }
          />
        </Box>
      </Box>

      {/**application deadline*/}
      <TextField
        required
        variant="outlined"
        type="date"
        format="mm-dd-yyyy"
        fullWidth
        size="small"
        id="application_deadline"
        label="Application Deadline"
        InputLabelProps={{
          shrink: true,
        }}
        value={formik.values.application_deadline}
        onChange={formik.handleChange("application_deadline")}
        error={
          formik.touched.application_deadline &&
          Boolean(formik.errors.application_deadline)
        }
        helperText={
          formik.touched.application_deadline &&
          formik.errors.application_deadline
        }
      />

      {/**receive updates */}
      <TextField
        required
        variant="outlined"
        fullWidth
        size="small"
        id="receive_updates"
        label="Receive Updates"
        value={formik.values.receive_updates}
        onChange={formik.handleChange("receive_updates")}
        error={
          formik.touched.receive_updates &&
          Boolean(formik.errors.receive_updates)
        }
        helperText={
          formik.touched.receive_updates && formik.errors.receive_updates
        }
        select
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
      >
        {updateOption.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setCurrentStep(currentStep - 1)
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={e => {
            formik.handleSubmit(e)
          }}
        >
          NEXT
        </Button>
      </Box>
    </Box>
  )
}

export default About
