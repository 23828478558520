import React, { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation, Link } from "react-router-dom"
import AdminLayout from "./AdminLayout"
import AdminHeader from "./AdminHeader"
import AppliedCard from "./AppliedCard"
import axios from "../api/axios"
import useAuth from "../hooks/useAuth"

const ApplicantFiles = () => {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState(-1)
  const [searchResults, setSearchResults] = useState([])
  const [indexOfLastApplicant, setIndexOfLastApplicant] = useState(5)
  let params = useParams()
  const { auth } = useAuth()
  const navigate = useNavigate()
  const currentApplicants = array => {
    const newArray = array?.slice(0, indexOfLastApplicant)
    return newArray
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
      const res = await axios
        .get(`applications/applicants/${params.id}`)
        .catch(error => {
          console.error(error?.res)
        })
      if (res) {
        isMounted && setData(res?.data?.data)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])
  return (
    <AdminLayout page="applicants">
      <AdminHeader page="admin-dashboard" />
      <div className="px-12 py-6 space-y-4">
        <div className="bg-white py-6 px-4 rounded-md shadow space-y-3">
          <span className="text-black">New Applicants</span>
          {/* {data?.applicants?.map((app, index) => (
              <Link to={`/jobs/${params.id}/applications`} key={app?.id}>
                <div className="py-2 border-b border-gray-100">
                  <AppliedCard data={app}/>
                </div>
              </Link>
            ))} */}
          {data?.applicants?.map(app => (
            <Link to={`/jobs/${params.id}/applications`}>
              <AppliedCard key={app?.id} data={app} />
            </Link>
          ))}

          {searchResults.length > indexOfLastApplicant && (
            <button className="border-none pt-3 w-full text-center text-blue-700 hover:text-blue-400">
              Show More
            </button>
          )}
        </div>
        {/* <div className="bg-white py-6 px-4 rounded-md shadow space-y-3">
            <span className="text-black">New Applicants</span>
            {data?.applicants?.map((app, index) => (
              <Link to={`/jobs/${params.id}/applications`} key={app?.id}>
                <div className="py-2 border-b border-gray-100">
                  <AppliedCard data={app} />
                </div>
              </Link>
            ))} */}

        {/* {data.length > indexOfLastApplicant && (
              <button
                className="border-none pt-3 w-full text-center text-blue-700 hover:text-blue-400"
                onClick={() => setIndexOfLastApplicant(indexOfLastApplicant + 5)}
              >
                Show More
              </button>
          
            )} */}
      </div>
    </AdminLayout>
  )
}

export default ApplicantFiles
