import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AdminLayout from "../AdminLayout"
import AdminAssessmentHeader from "../AdminAssessmentHeader"
import axios, { axiosPrivate } from "../../api/axios"
import Box from "@mui/material/Box"
import Stepper from "../Stepper"
import useAuth from "../../hooks/useAuth"
import Swal from "sweetalert2"
import AssessmentHomePage from "./AssessmentHomePage"
import PreviewQuestions from "./PreviewQuestions"
import MultiChoiceQuestions from "./MultiChoiceQuestions"
import { Helmet } from "react-helmet-async"

function CreateQuestions() {
  const [jobrole, setJobRole] = useState()
  const [roles, setRoles] = useState([])
  const steps = ["HomePage", "Questions", "Preview"]
  const [currentStep, setCurrentStep] = useState(1)
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [questions, setQuestions] = useState([
    {
      question: "",
      option_A: "",
      option_B: "",
      option_C: "",
      option_D: "",
      correct_option: "",
      time: "",
    },
  ])

  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  }

  const submit = async () => {
    if (currentStep === 3) {
      const res = await axiosPrivate
        .post(
          `assessment/`,
          {
            company_id: auth?.companyId,
            role: jobrole?.value,
            multiple_questions: questions,
          },
          request
        )
        .catch(error => {
          console.error(error?.response)
          if (error?.response) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: "Oops...",
              text: error?.response?.data?.message,
              position: "top",
              timer: 2000,
              showConfirmButton: false,
            })
          }
        })
      if (res) {
        if (res?.status === 201) {
          Swal.fire({
            toast: true,
            icon: "success",
            title: "Your questions has been Created successfully.",
            text: res?.data?.message,
            position: "top",
            timer: 2000,
            showConfirmBottom: false,
          })
        }
        navigate("/admin-dashboard")
      }
    }
  }

  const displayStep = step => {
    switch (step) {
      case 1:
        return (
          <AssessmentHomePage
            setJobRole={setJobRole}
            jobrole={jobrole}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            roles={roles}
          />
        )
      case 2:
        return (
          <MultiChoiceQuestions
            questions={questions}
            setQuestions={setQuestions}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      case 3:
        return (
          <PreviewQuestions
            questions={questions}
            jobrole={jobrole}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            submit={submit}
          />
        )
      default:
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getJobRoles = async () => {
      const res = await axios
        .get(`assessment/roles/${auth?.companyId}`)
        .catch(err => {
          console.error(err?.res)
        })
      if (res) {
        isMounted && setRoles(res.data.data)
      }
    }

    getJobRoles()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  return (
    <AdminLayout page="assessment">
      <Helmet>
        <title>Create Assessment | Edge</title>
      </Helmet>

      <div className="min-h-screen">
        <AdminAssessmentHeader page="create-job" />

        <div className="flex flex-col w-3/4 border-2 rounded mx-auto my-8">
          <Box>
            <Stepper steps={steps} currentStep={currentStep} />
            <Box>{displayStep(currentStep)}</Box>
          </Box>
        </div>
      </div>
    </AdminLayout>
  )
}

export default CreateQuestions
