import { useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import AdminLayout from "../components/AdminLayout"
import AdminHeader from "../components/AdminHeader"
import useAuth from "../hooks/useAuth"
import AppCard from "../components/AppCard"
import JobApplicationDetails from "../components/JobApplicationDetails"
import { FaLongArrowAltLeft } from "react-icons/fa"
import { BiSortAlt2 } from "react-icons/bi"
import { statusFilters } from "../utils/data"
import { Select, MenuItem, InputAdornment } from "@mui/material"
import { useQuery } from "react-query"
import useFetcherPrivate from "../hooks/useFetcherPrivate"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import CommentSection from "../components/comment/CommentSection"
import { Helmet } from "react-helmet-async"

const JobApplications = () => {
  const [page, setPage] = useState(1)
  const [sortedBy, setSortedBy] = useState("Most Recent")
  const [applicantStatus, setApplicantStatus] = useState("All")
  const [item, setItem] = useState()
  let params = useParams()
  const { auth } = useAuth()
  const navigate = useNavigate()
  const fetcherPrivate = useFetcherPrivate()

  const { data: screeningQuestions } = useQuery(["screening"], () =>
    fetcherPrivate(`jobs/screening/${params?.id}`, auth?.accessToken)
  )

  const {
    data: applications,
    isError,
    isLoading,
    isSuccess,
  } = useQuery(["applications", applicantStatus], () =>
    fetcherPrivate(
      `applications/applicants/${params.id}?status=${applicantStatus}`
    )
  )

  return (
    <AdminLayout page="jobs">
      <Helmet>
        <title>Applications | Edge</title>
      </Helmet>

      <AdminHeader page="manage-jobs" />

      <div className="xl:px-12 sm:px-6 px-4 py-6 space-y-4 w-full">
        <button
          onClick={() => {
            if (page === 2) {
              setPage(1)
            } else {
              navigate(-1)
            }
          }}
          className="flex py-1.5 px-6 text-black items-center hover:bg-gray-200 hover:w-1/5 hover:rounded-2xl"
        >
          <FaLongArrowAltLeft className="mr-2" />
          Go back
        </button>

        {page === 1 && (
          <div className="bg-white py-6 px-6 rounded-md shadow space-y-8">
            <div className="flex sm:flex-row sm:space-x-6 sm:space-y-0 sm:items-center flex-col space-y-6">
              <p className="text-lg font-medium text-black">Applications</p>

              {/* filters */}
              <div className="flex sm:flex-row sm:space-x-4 sm:space-y-0 flex-col space-y-4 items-center">
                <Select
                  size="small"
                  value={sortedBy}
                  startAdornment={
                    <InputAdornment position="start">
                      <BiSortAlt2 />
                    </InputAdornment>
                  }
                  sx={{
                    fontSize: "14px",
                    boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 0.1)",
                  }}
                  onChange={e => {
                    setSortedBy(e.target.value)
                  }}
                  MenuProps={{
                    MenuListProps: { sx: { padding: "0px" } },
                  }}
                >
                  <MenuItem value="Most Recent">Most Recent</MenuItem>
                </Select>

                <Select
                  size="small"
                  value={applicantStatus}
                  sx={{
                    fontSize: "14px",
                    boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 0.1)",
                  }}
                  onChange={e => {
                    setApplicantStatus(e.target.value)
                  }}
                  MenuProps={{
                    MenuListProps: { sx: { padding: "0px" } },
                  }}
                >
                  {statusFilters.map((item, index) => (
                    <MenuItem
                      value={item.value}
                      key={index}
                      sx={{ fontSize: "12px" }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            {/* isLoading */}
            {isLoading && (
              <div className="grid 4xl:grid-cols-5 3xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 w-full">
                <Skeleton style={{ height: "120px" }} />
                <Skeleton style={{ height: "120px" }} />
              </div>
            )}

            {/* isSuccess */}
            {isSuccess && (
              <div className="grid 4xl:grid-cols-5 3xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 w-full">
                {applications?.map((applicant, index) => (
                  <AppCard
                    applicant={applicant}
                    setPage={setPage}
                    setItem={setItem}
                    key={index}
                  />
                ))}
              </div>
            )}

            {/* isError */}
            {isError && (
              <div className="flex flex-col w-full justify-center">
                <div className="h-32 flex justify-center">
                  <img
                    src={require("../assets/no-data.webp")}
                    alt=""
                    className="h-full w-auto"
                    loading="lazy"
                  />
                </div>
                <p className="text-lg text-center text-black font-medium">
                  No data found with current filters.
                </p>
              </div>
            )}
          </div>
        )}

        {page === 2 && (
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-6">
            <div className="sm:col-span-2 col-span-1 bg-white w-full rounded-md shadow">
              <JobApplicationDetails
                item={item}
                screeningQuestions={screeningQuestions}
                setApplicantStatus={setApplicantStatus}
                applicantStatus={applicantStatus}
              />
            </div>

            <div className="col-span-1 bg-white px-6 py-6 w-full rounded-md shadow">
              <CommentSection />
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  )
}

export default JobApplications
