import React from "react"
import { titleCase } from "../utils/helpers"
import { useNavigate } from "react-router-dom"


function AppliedJobCard({ data }) {
  const navigate = useNavigate()

  return (
    <div className="bg-white rounded-md w-full py-2.5 xl:px-4 px-2.5 text-black">
      <div className="flex">
        <div className="w-12 md:w-16">
          {data.company_logo ? (
            <div className="w-full h-auto bg-white flex items-center mt-2.5">
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BASE_URL}storage/images/${data?.company_logo}`}
                alt=""
              />
            </div>
          ) : (
            <div className="w-full h-auto bg-gray-100"></div>
          )}
        </div>

        <div className="w-auto pl-2 mx-1 flex flex-col">
          <div className="h-auto sm:h-16 xl:h-20 flex flex-col">
            <span className="text-blueBtn font-medium text-xs xl:text-sm group-hover:underline xl:break-words">
              {titleCase(data?.job_title)}
            </span>
            <span className="text-black text-xs">
              {titleCase(data?.company_name)}
            </span>
            <span className="text-gray-500 text-xs">{`${titleCase(
              data.job_city
            )}, ${titleCase(data?.job_country)}`}</span>
          </div>
          <button
            className="bg-blueBtn text-white text-sm py-1.5 px-6 rounded mt-2"
            onClick={() => {
              navigate(
                `/job-description/${data?.id}/${data?.job_title} at ${data?.company_name}`
              )
            }}
          >
            View Job
          </button>
        </div>
      </div>
    </div>
  )
}

export default AppliedJobCard
