import { useEffect, useState } from "react"
import { useNavigate, Link, useLocation } from "react-router-dom"
import { ImSpinner3 } from "react-icons/im"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import AdminLayout from "../AdminLayout"
import AdminHeader from "../AdminHeader"
import useAuth from "../../hooks/useAuth"
import AssessmentDataCard from "./AssessmentDataCard"
import { Helmet } from "react-helmet-async"

const ManageHome = () => {
  const [assessmentData, setAssessmentData] = useState([])

  const [searchResults, setSearchResults] = useState([])
  const [query, setQuery] = useState("")
  const [indexOfLastJob, setIndexOfLastJob] = useState(5)

  const [error, setError] = useState()

  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const getData = async () => {
      const res = await axiosPrivate
        .get(`assessment/${auth?.companyId}`, request)
        .catch(err => {
          console.error(err?.response)
          setError(err?.response.data.message)
          // navigate("/admin-login", { state: { from: location }, replace: true })
        })
      if (res?.data) {
        isMounted && setAssessmentData(res?.data?.data)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  return (
    <AdminLayout page="assessments">
      <Helmet>
        <title>Assessments | Edge</title>
      </Helmet>

      <AdminHeader
        page="manage-jobs"
        setSearchResults={setSearchResults}
        setQuery={setQuery}
      />
      {assessmentData ? (
        <div className="xl:px-12 sm:px-6 px-4 py-6 space-y-4 w-full">
          <div className="bg-white py-6 px-4 rounded-md shadow space-y-3">
            <span className="text-black">Search Results</span>
          </div>
          <div className="bg-white py-4 rounded-md shadow">
            <span className="text-black xl:text-base text-sm font-bold mx-4">
              MANAGE APPLICANT ASSESSMENT
            </span>
            <div className="grid 4xl:grid-cols-6 3xl:grid-cols-5 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 px-3">
              {assessmentData.map((job, index) => (
                <div className="px-3 py-4" key={index}>
                  <AssessmentDataCard data={job} />
                </div>
              ))}
            </div>

            {assessmentData.length > indexOfLastJob && (
              <button
                className="border-none pt-3 w-full text-center text-blue-700 hover:text-blue-400"
                onClick={() => setIndexOfLastJob(indexOfLastJob + 5)}
              >
                Show More
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen -mt-16">
          {error === "No job found!" ? (
            <div className="flex flex-col text-black">
              <img
                src={require("../../assets/jobs.png")}
                alt=""
                width="150px"
                height="150px"
              />
              <p className="text-xl">No Data Found!</p>
            </div>
          ) : (
            <span className="flex text-black space-x-3 items-center justify-center">
              <ImSpinner3 size={48} className="animate-spin" />
              <p className="text-3xl">Loading</p>
            </span>
          )}
        </div>
      )}
    </AdminLayout>
  )
}

export default ManageHome
