import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import axios from "../../api/axios"
import { IoMdSwitch } from "react-icons/io"
import { HiOutlineLightBulb } from "react-icons/hi"
import Timer from "./Timer"
import { axiosPrivate } from "../../api/axios"
import Swal from "sweetalert2"
import { Helmet } from "react-helmet-async"

const AssessmentQuiz = ({ query, setPage }) => {
  const [index, setIndex] = useState(0)
  const [questions, setQuestions] = useState([])
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [answers, setAnswers] = useState([])
  const [showTimer, setShowTimer] = useState(false)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getQuestions = async () => {
      const res = await axios
        .get(`assessment/question/${query?.id}`)
        .catch(error => {
          console.error(error?.res)
        })
      if (res) {
        isMounted && setQuestions(res?.data?.data)
        setShowTimer(true)
      }
    }

    getQuestions()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [query])

  const handlePrev = () => {
    if (index > 0) {
      setIndex(index - 1)
    }
  }

  //handling next question when time is up.
  const gotoNextQuestion = e => {
    setShowTimer(false)
    if (index < questions?.length - 1) {
      // Swal.fire({
      //   title: "Your time for this question is up!",
      //   text: "",
      //   icon: "warning",
      //   confirmButtonColor: "#3085d5",
      //   allowOutsideClick: false,
      //   confirmButtonText: "Ok",
      // }).then(result => {
      //   if (result.isConfirmed) {
      setIndex(index + 1)
      setShowTimer(true)
      //   }
      // })
    } else {
      submit(e)
    }
  }

  //handling next question.
  const handleNext = e => {
    if (index < questions?.length - 1) {
      Swal.fire({
        title: "You can not return to prvious question when Next is clicked!",
        text: "",
        icon: "warning",
        showCancelButton: "true",
        confirmButtonColor: "#3085d5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(result => {
        if (result.isConfirmed) {
          setIndex(index + 1)
          setShowTimer(true)
        }
      })
    } else {
      submit(e)
    }
  }

  const handleOnSelect = (question, index, answer) => {
    let data = [...answers]
    data[index] = {
      question_no: question?.id,
      answer: answer,
    }
    setAnswers(data)
  }

  const submit = e => {
    e.preventDefault()
    Swal.fire({
      title: "Are you sure you want to submit?",
      text: "",
      icon: "warning",
      showCancelButton: "true",
      confirmButtonColor: "#3085d5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await axiosPrivate
          .post(`assessment/answers`, {
            question_id: questions[0]?.question_id,
            user_id: auth?.id,
            multiple_answers: answers,
          })
          .catch(error => {
            console.error(error?.response)
            if (error?.response) {
              Swal.fire({
                toast: true,
                icon: "error",
                title: "Oops...",
                text: error?.response?.data?.message,
                position: "top",
                timer: 2000,
                showConfirmButton: false,
              })
            }
          })
        if (res) {
          if (res?.status === 201) {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Your assessment has been submitted successfully.",
              text: res?.data?.message,
              position: "top",
              timer: 2000,
              showConfirmBottom: false,
            })
            navigate("/candidate-dashboard")
          }
        }
      }
    })
  }

  const handleQuit = item => {
    Swal.fire({
      title: "Are you sure you want to quit?",
      text: "",
      icon: "warning",
      showCancelButton: "true",
      confirmButtonColor: "#3085d5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(result => {
      if (result.isConfirmed) {
        setPage(1)
      }
    })
  }

  return (
    <div className="bg-slate-100 border-l-4 border-l-blue-500 px-8 mt-28 shadow-xl md:container md:mx-auto">
      <Helmet>
        <title>{query?.role} | Edge</title>
      </Helmet>

      <h2 className="text-slate-800 text-2xl font-semibold text-center py-6 font-['Quicksand']">
        Assessment Test
      </h2>
      <div className="flex justify-between">
        <p className="flex items-center pb-1">
          <IoMdSwitch className="text-blue-300" size={24} />
          <p className="text-slate-900">Question</p>
        </p>
        <p className="flex items-center pb-1">
          <HiOutlineLightBulb className="text-yellow-400" size={24} />
          <p className="text-slate-900">Good luck</p>
        </p>
      </div>
      <div className="timer-container ">
        <p className="flex justify-between">
          <span className="text-slate-800">
            {index + 1} of {questions?.length}
          </span>

          <span className="flex items-center text-slate-900">
            {showTimer && questions[index]?.time && (
              <Timer
                time={questions[index]?.time}
                gotoNextQuestion={gotoNextQuestion}
              />
            )}
          </span>
        </p>
      </div>

      <h5 className="text-sm text-center text-slate-600">
        {questions[index]?.question}
      </h5>

      <div className="flex sm:flex-row flex-col items-center justify-between w-full">
        <p
          className={`${
            answers[index]?.answer === "A" ? "bg-blue-300" : "bg-blue-500"
          } rounded-xl text-white cursor-pointer p-2 hover:transition duration-150 ease-out hover:bg-blue-300 hover:opacity-60 text-center w-[48%] mt-4`}
          onClick={() => handleOnSelect(questions[index], index, "A")}
        >
          A. {questions[index]?.option_A}
        </p>
        <p
          className={`${
            answers[index]?.answer === "B" ? "bg-blue-300" : "bg-blue-500"
          } rounded-xl text-white cursor-pointer p-2 hover:transition duration-150 ease-out hover:bg-blue-300 hover:opacity-60 text-center w-[48%] mt-4`}
          onClick={() => handleOnSelect(questions[index], index, "B")}
        >
          B. {questions[index]?.option_B}
        </p>
      </div>
      <div className="flex sm:flex-row flex-col items-center justify-between w-full">
        <p
          className={`${
            answers[index]?.answer === "C" ? "bg-blue-300" : "bg-blue-500"
          } rounded-xl text-white cursor-pointer p-2 hover:transition duration-150 ease-out hover:bg-blue-300 hover:opacity-60 text-center w-[48%] mt-4`}
          onClick={() => handleOnSelect(questions[index], index, "C")}
        >
          C. {questions[index]?.option_C}
        </p>
        <p
          className={`${
            answers[index]?.answer === "D" ? "bg-blue-300" : "bg-blue-500"
          } rounded-xl text-white cursor-pointer p-2 hover:transition duration-150 ease-out hover:bg-blue-300 hover:opacity-60 text-center w-[48%] mt-4`}
          onClick={() => handleOnSelect(questions[index], index, "D")}
        >
          D. {questions[index]?.option_D}
        </p>
      </div>

      <div className="flex justify-between w-3/4 p-6 md:mx-auto mt-0 mb-3">
        <div>
          <button
            className="bg-blue-300 font-['Quicksand'] text-white cursor-pointer mr-6 py-1 px-3 hover:transition duration-150 ease-out hover:bg-blue-300 drop-shadow-xl outline outline-1"
            disabled
            onClick={handlePrev}
          >
            Previous
          </button>
          <button
            className="bg-green-400 font-['Quicksand'] text-white cursor-pointer mr-6 py-1 px-3 hover:transition duration-150 ease-out hover:bg-green-600 drop-shadow-xl outline outline-1"
            onClick={e => {
              setShowTimer(false)
              handleNext(e)
            }}
          >
            Next
          </button>
        </div>
        <div>
          <button
            className="bg-red-500 font-['Quicksand'] text-white cursor-pointer mr-6 py-1 px-3 hover:transition duration-150 ease-out hover:bg-red-600 drop-shadow-xl outline outline-1"
            onClick={handleQuit}
          >
            Quit
          </button>
          <button
            className="bg-blue-400 font-['Quicksand'] text-white cursor-pointer mr-6 py-1 px-3 hover:transition duration-150 ease-out hover:bg-red-600 drop-shadow-xl outline outline-1"
            onClick={e => submit(e)}
          >
            Finish
          </button>
        </div>
      </div>
    </div>
  )
}

export default AssessmentQuiz
