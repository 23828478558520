import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios, { axiosPrivate } from "../../api/axios";

import useAuth from "../../hooks/useAuth";
import Swal from "sweetalert2";
import AdminLayout from "../../components/AdminLayout";
import AdminHeader from "../../components/AdminHeader";

import ManageHome from "../../components/ManageAssessment/ManageHome";




function AdminManageAssessment() {
  
  const { auth } = useAuth();


  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  };

  

  return (
    <AdminLayout page="">
      <AdminHeader page="create-job" />
      <ManageHome />
     
    </AdminLayout>
  );
}

export default AdminManageAssessment;

