import { FiEdit, FiDelete } from "react-icons/fi"
import Swal from "sweetalert2"
import axios from "../api/axios"
import "../styles/ApplicantList.css"
import { timestampToDate, titleCase } from "../utils/helpers"

const AdminList = ({ data, search, setOpenModal, setAdminData }) => {
  const handleEdit = item => {
    setOpenModal(true)
    setAdminData(item)
  }

  const handleDelete = item => {
    Swal.fire({
      title: "Are you sure you want to deactivate this Admin?",
      text: "This action cannot be undone",
      icon: "warning",
      showCancelButton: "true",
      confirmButtonColor: "#3085d5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await axios
          .delete(`companies/admins/${item?.id}`)
          .catch(err => {
            console.error(err.response)
          })

        if (res?.status === 201) {
          Swal.fire("Deactivated!", "Admin Deactivated.", "success")
        }
      }
    })
  }

  const alternatingStyles = [
    "bg-white text-slate-700",
    "bg-slate-300 text-white",
  ]

  return (
    <div className="">
      <table className="border-none w-full">
        <thead>
          <tr className="bg-slate-600 w-full">
            <th className="w-1/12"></th>
            <th className="w-2/12">FIRST NAME</th>
            <th className="w-2/12">LAST NAME</th>
            <th className="hideprop w-2/12">COMPANY EMAIL</th>
            <th className="hideprop w-2/12">DATE ADDED</th>
            <th className="w-1/12">STATUS</th>
          </tr>
        </thead>
        <tbody className="w-full relative">
          {data?.length > 0 &&
            data.map((item, index) => {
              return (
                <tr
                  key={item?.id}
                  className={`${
                    alternatingStyles[index % alternatingStyles.length]
                  } py-4 relative`}
                >
                  <td className="flex items-center justify-center">
                    <FiEdit
                      className="mr-8 cursor-pointer"
                      onClick={() => handleEdit(item)}
                    />
                    <FiDelete
                      className="cursor-pointer"
                      onClick={() => handleDelete(item)}
                    />
                  </td>
                  <td>{`${titleCase(item?.first_name)}`}</td>
                  <td>{`${titleCase(item?.last_name)}`}</td>
                  <td className="hideprop">{item?.email}</td>
                  <td className="hideprop">
                    {timestampToDate(item?.created_at)}
                  </td>
                  <td className="">{titleCase(item?.status)}</td>
                </tr>
              );
            })};
        </tbody>
      </table>
    </div>
  );
};

export default AdminList;
