import { FaUser } from "react-icons/fa"
import { titleCase } from "../utils/helpers"

const AppCard = ({ applicant, setPage, setItem }) => {
  return (
    <div className="w-full py-2.5 px-3 text-black rounded-md shadow-md border border-gray-200 cursor-pointer">
      <div className="flex flex-col md:flex-row items-center">
        <div className="w-16 h-16 rounded-full bg-gray-300 mr-0 md:mr-4 relative">
          {applicant?.avatar ? (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_BASE_URL}storage/images/${applicant?.avatar}`}
              className="w-full h-full object-center rounded-full"
              alt=""
            />
          ) : (
            <FaUser
              size={24}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
            />
          )}
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col md:flex-row mt-1 md:mt-0 items-center">
            <div className="text-center">
              <p className="text-sm md:text-normal">{`${titleCase(
                applicant?.first_name
              )} ${titleCase(applicant?.last_name)}`}</p>

              <p className="text-xs md:text-sm">{applicant?.email}</p>
              <p className="text-xs md:text-sm">{applicant?.phone_number}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-1.5 flex justify-center">
        <button
          className="bg-blueBtn text-white text-sm mt-2 py-1.5 px-4 rounded-2xl"
          onClick={() => {
            setPage(2)
            setItem(applicant)
          }}
        >
          View More
        </button>
      </div>
    </div>
  )
}

export default AppCard
