import { useEffect, useState } from "react"
import axios from "../api/axios"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useNavigate } from "react-router-dom"
import AdminHeader from "../components/AdminHeader"
import AdminLayout from "../components/AdminLayout"
import AddAdminModal from "../components/AddAdminModal"
import EditAdminModal from "../components/EditAdminModal"
import AdminList from "../components/AdminList"
import { Helmet } from "react-helmet-async"

const ManageAdmin = () => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [adminData, setAdminData] = useState("")
  const [page, setPage] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [query, setQuery] = useState("")
  const [data, setData] = useState()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const fetchData = async () => {
      const res = await axios
        .get(`admins/companies/${auth.companyId}`, request)
        .catch(err => {
          console.error(err.response)
        })

      if (res?.data?.data) {
        isMounted && setData(res.data?.data)
      }
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [page, auth])

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const handleNext = () => {
    setPage(page + 1)
  }

  const search = array => {
    return array.filter(
      item =>
        item?.company?.first_name?.includes(query) ||
        item?.company?.last_name?.includes(query) ||
        item?.company?.company_email?.includes(query) ||
        item?.company?.status?.includes(query)
    )
  }

  return (
    <AdminLayout page="manage-admin">
      <Helmet>
        <title>Admin Management | Edge</title>
      </Helmet>

      <AdminHeader page="manage-admin" />

      <div className="w-full sm:py-10 py-5 sm:px-8 px-2">
        {showModal && (
          <AddAdminModal showModal={showModal} setShowModal={setShowModal} />
        )}

        {openModal && (
          <EditAdminModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            adminData={adminData}
          />
        )}

        <div className="flex mb-8">
          <h2 className="text-black">Add a new Admin </h2>
          <button
            className="bg-blueBtn px-2 mx-4"
            onClick={() => setShowModal(true)}
          >
            +
          </button>
        </div>

        <AdminList
          data={data}
          search={search}
          setOpenModal={setOpenModal}
          setAdminData={setAdminData}
        />

        <div className="justify-between mt-8 hidden">
          <button
            className="text-slate-700 border-2 border-slate-700 py-2 px-4 rounded-xl disabled:bg-slate-50 disabled:text-gray-700 disabled:border-gray-700 hover:bg-slate-700 hover:text-white"
            disabled={page === 1}
            onClick={() => handlePrev()}
          >
            Prev
          </button>
          <button
            className="text-slate-700 border-2 border-slate-700 py-2 px-4 rounded-xl disabled:bg-slate-50 disabled:text-gray-700 disabled:border-gray-700 hover:bg-slate-700 hover:text-white"
            // disabled={appLists?.count?.count_applicant <= page * 10}
            onClick={() => handleNext()}
          >
            Next
          </button>
        </div>
      </div>
    </AdminLayout>
  )
}

export default ManageAdmin
