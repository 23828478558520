import { useState } from "react"
import CreateJobHeader from "../components/CreateJobHeader"
import AdminLayout from "../components/AdminLayout"
//import {currency} from "../utils/data.js"
import { About, Details, Preview, Screening } from "../components/forms/job"
//import Controls from "../components/forms/job/Controls"
import Stepper from "../components/forms/job/Stepper"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet-async"

const detailsSchema = yup.object({
  logo: yup.mixed().required("Choose a file"),
  job_title: yup
    .string("Enter your job's title")
    .required("This field is required"),
  job_level: yup
    .string("Select your job's level")
    .required("This field is required"),
  company_name: yup
    .string("Enter your job's company name")
    .required("This field is required"),
  job_country: yup
    .string("Enter your job's country")
    .required("This field is required"),
  job_city: yup
    .string("Enter your job's location city")
    .required("This field is required"),
  contract_type: yup
    .string("Select your job's contract type")
    .required("This field is required"),
  employment_type: yup
    .string("Select your job's employment type")
    .required("This field is required"),
  openings: yup
    .number("Enter your job's number of openings")
    .required("This field is required"),
})

const aboutSchema = yup.object({
  currency: yup.string("Enter currency").required("This field is required"),
  min_salary_range: yup.string("Enter minimum salary"),

  max_salary_range: yup.string("Enter maximum salary"),

  application_deadline: yup
    .string("Enter your job's application deadline")
    .required("This field is required"),
  receive_updates: yup
    .string("Select whether to receieve updates for this job")
    .required("This field is required"),
})

const AddJob = () => {
  const [screeningQuestions, setScreeningQuestions] = useState([])
  const [jobDescription, setJobDescription] = useState("")
  //const [applicationDeadline, setApplicationDeadline] = useState(new Date())
  const steps = ["Details", "About", "Screening", "Preview"]
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  }

  const formik = useFormik({
    initialValues: {
      logo: "",
      job_title: "",
      job_level: "",
      company_name: "",
      job_country: "",
      job_city: "",
      contract_type: "",
      employment_type: "",
      openings: "",
      currency: "NGN",
      min_salary_range: "",
      max_salary_range: "",
      application_deadline: "",
      receive_updates: "",
    },
    validationSchema: currentStep === 2 ? aboutSchema : detailsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1)
      }

      if (currentStep === 4) {
        const formData = new FormData()
        formData.append("file", values.logo)
        const upload = await axiosPrivate
          .post(`storage/upload-image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${auth.accessToken}`,
            },
          })
          .catch(err => console.error(err))

        if (upload) {
          const response = await axiosPrivate
            .post(
              `jobs/`,
              {
                company_id: auth?.companyId,
                admin_id: auth?.id,
                job_title: values.job_title,
                job_level: values.job_level,
                company_name: values.company_name,
                company_logo: upload.data.data.filename,
                job_country: values.job_country,
                job_city: values.job_city,
                contract_type: values.contract_type,
                employment_type: values.employment_type,
                openings: values.openings,
                job_description: jobDescription,
                currency: values.currency,
                salary_range:
                  values.min_salary_range && values.max_salary_range
                    ? `${values.min_salary_range} - ${values.max_salary_range}`
                    : "",
                application_deadline: values.application_deadline,
                receive_updates: values.receive_updates,
                job_screening: screeningQuestions,
              },
              request
            )
            .catch(error => {
              Swal.fire({
                toast: true,
                icon: "error",
                title: "Oops...",
                text: error?.response?.data?.message,
                position: "top",
                timer: 3000,
                showConfirmButton: false,
              })
            })

          if (response) {
            resetForm()
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Job Created",
              text: response?.data?.message,
              position: "top",
              timer: 3000,
              showConfirmBottom: false,
            })
            navigate("/jobs/")
          }
        }
      }
    },
  })

  const displayStep = step => {
    switch (step) {
      case 1:
        return <Details formik={formik} />
      case 2:
        return (
          <About
            formik={formik}
            jobDescription={jobDescription}
            setJobDescription={setJobDescription}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      case 3:
        return (
          <Screening
            screeningQuestions={screeningQuestions}
            setScreeningQuestions={setScreeningQuestions}
            formik={formik}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      case 4:
        return (
          <Preview
            formik={formik}
            screeningQuestions={screeningQuestions}
            jobDescription={jobDescription}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      default:
    }
  }

  return (
    <AdminLayout page="jobs/add">
      <Helmet>
        <title>Create Job | Edge</title>
      </Helmet>

      <CreateJobHeader page="create-job" />
      <div className="w-full">
        <div className="mx-auto rounded-2xl bg-white pb-2 shadow-xl lg:w-3/5 sm:w-3/4 w-full py-6 px-4 sm:my-10 my-0">
          <Stepper steps={steps} currentStep={currentStep} />
          <div className="my-4 py-4 px-6">{displayStep(currentStep)}</div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default AddJob
