export const titleCase = str => {
  if (str === undefined || !str) {
    return str
  }

  const words = str?.split(" ")

  const result = words.map(word => {
    return word.replace(word.charAt(0), word.charAt(0).toUpperCase())
  })

  // const result = words.map(word => {
  //   return word[0].toUpperCase() + word.substring(1)
  // })

  return result.join(" ")
}

export const timestampToYYYYMMDD = createdAt => {
  const myDate = new Date(createdAt)
  let day = myDate.getDate()
  let month = myDate.getMonth() + 1

  if (day <= 9) {
    day = `0${day}`
  }

  if (month <= 9) {
    month = `0${month}`
  }

  return `${myDate.getFullYear()}/${month}/${day}`
}

export const timestampToDate = createdAt => {
  const myDate = new Date(createdAt)
  const dateSuffix = getDateSuffix(myDate.getDate())
  const month = myDate.toLocaleString("default", { month: "short" })

  return `${myDate.getDate()}${dateSuffix} ${month} ${myDate.getFullYear()}`
}

const getDateSuffix = day => {
  switch (day) {
    case 1:
    case 21:
    case 31:
      return "st"
    case 2:
    case 22:
      return "nd"
    case 3:
    case 23:
      return "rd"
    default:
      return "th"
  }
}

export const getFileName = url => {
  let arr1 = url?.split("edge/")
  let arr2 = arr1[1].split("_")
  arr2.pop()
  const fileName = titleCase(arr2.join(" "))
  return fileName
}

export const getFileExt = str => {
  if (str?.includes(".doc")) return "DOC"
  if (str?.includes(".docx")) return "DOCX"
  return "PDF"
}

export const getSalaryRange = str => {
  if (str === undefined || !str) {
    return str
  }
  let arr = str.split("-")
  let obj = {
    min_range: arr[0].trim(),
    max_range: arr[1].trim(),
  }
  return obj
}

export function isInThePast(date) {
  const today = new Date()

  today.setHours(0, 0, 0, 0)

  return date < today
}

export const getPrevURL = url => {
  const urlString = url?.split(process.env.REACT_APP_PRODUCTION_URL)

  return urlString[1]
}

export const changeDateFormat = date => {
  const dateArray = date?.split("/")
  if (dateArray === undefined || !dateArray) {
    return dateArray
  }
  return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
}

export const changeApplicationDeadlineFormat = date => {
  const dateArray = date?.split("/")

  const myDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
  if (myDate === undefined || !myDate) {
    return myDate
  }
  const dateSuffix = getDateSuffix(myDate.getDate())
  const month = myDate.toLocaleString("default", { month: "short" })
  return `${myDate.getDate()}${dateSuffix} ${month} ${myDate.getFullYear()}`
}

export const filteredUnexpiredJobcards = data => {
  const currentDate = new Date()
  return data?.filter(data => {
    const expirationDate = new Date(data?.application_deadline)
    return expirationDate >= currentDate
  })
}
