import { useEffect, useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import AdminLayout from "../components/AdminLayout"
import { ImSpinner3 } from "react-icons/im"
import { FaBriefcase } from "react-icons/fa"
import parse from "html-react-parser"
import { getSalaryRange, titleCase } from "../utils/helpers"
import AdminHeader from "../components/AdminHeader"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import AppliedCard from "../components/AppliedCard"
import EditJob from "../components/editJob"
import { FaLongArrowAltLeft } from "react-icons/fa"
import { Helmet } from "react-helmet-async"

const JobDetails = () => {
  const [data, setData] = useState()
  const [salaryRange, setSalaryRange] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  let params = useParams()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const getData = async () => {
      const response = await axiosPrivate
        .get(`jobs/${params?.id}`, request)
        .catch(error => {
          console.error(error?.response)
        })
      if (response) {
        isMounted && setData(response?.data?.data)
        isMounted &&
          setSalaryRange(getSalaryRange(response.data.data.job.salary_range))
      }
    }

    getData()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth?.accessToken, axiosPrivate, params?.title])

  return (
    <AdminLayout page="jobs">
      <Helmet>
        <title>
          {data
            ? `${data?.job.job_title} | ${data?.job.company_name} | Edge`
            : "Edge"}
        </title>
      </Helmet>

      <AdminHeader page="manage-jobs" />

      {isOpen && (
        <EditJob
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          salaryRange={salaryRange}
          data={data}
        />
      )}

      {data ? (
        <div className="xl:px-12 sm:px-8 px-4 py-6 space-y-4">
          <button
            onClick={() => navigate(-1)}
            className="flex py-1.5 px-6 text-black items-center hover:bg-gray-200 hover:w-1/5 hover:rounded-2xl"
          >
            <FaLongArrowAltLeft className="mr-2" />
            Go back
          </button>
          <div className="bg-white py-6 px-4 rounded-md shadow">
            <div className="py-3 text-black">
              <div className="space-y-4 px-6">
                <div className="flex">
                  <div className="mr-4">
                    {/* company logo */}
                    {data?.job.company_logo ? (
                      <div className="w-12 h-12 md:w-20 md:h-20 bg-white flex items-center">
                        <img
                          crossOrigin="anonymous"
                          src={`${process.env.REACT_APP_BASE_URL}storage/images/${data?.job.company_logo}`}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="w-12 h-12 md:w-20 md:h-20 bg-gray-100"></div>
                    )}
                  </div>

                  {/* job title, company name, location, employment type */}
                  <div>
                    <p className="text-2xl font-semibold">
                      {titleCase(data.job.job_title)}
                    </p>
                    <span className="text-sm">{`${titleCase(
                      data.job.company_name
                    )}. ${titleCase(data.job.job_city)}, ${titleCase(
                      data.job.job_country
                    )} (${titleCase(data.job.employment_type)})`}</span>
                  </div>
                </div>

                {/* job contract type */}
                <div>
                  <span className="flex items-center">
                    <FaBriefcase size={24} className="mr-2" />
                    <p>{titleCase(data.job.contract_type)}</p>
                  </span>
                </div>

                {/* job description */}
                <div className="list-disc">
                  {parse(data.job.job_description)}
                </div>

                {/* salary range */}
                {data.job.salary_range && data.job.currency.toUpperCase() ? (
                  <div>
                    <p className="text-base underline font-bold">
                      Salary Range
                    </p>
                    <p>{`${
                      data.job.salary_range
                    } ${data.job.currency.toUpperCase()}`}</p>
                  </div>
                ) : (
                  <div>
                    <p>Pay range unavailable</p>
                    <p className="text-sm text-gray-600">
                      Salary information is not available at the moment.
                    </p>
                  </div>
                )}

                {/* application deadline */}
                <div>
                  <p className="text-base underline font-bold">
                    Application Deadline
                  </p>
                  <p>{titleCase(data.job.application_deadline)}</p>
                </div>

                {/* screening questions */}
                {data.screeningQuestions.length > 0 && (
                  <div>
                    <p className="text-base underline font-bold">
                      Additional Questions
                    </p>
                    {data.screeningQuestions.map((question, index) => (
                      <ul key={index} className="mb-1 list-disc px-4">
                        {question.screening_question_group ===
                        "tool or technology" ? (
                          <li>{`How many years of experience do you have using ${titleCase(
                            question.screening_question_value
                          )}?`}</li>
                        ) : null}

                        {question.screening_question_group === "language" ? (
                          <li>{`What is your level of proficiency in ${titleCase(
                            question.screening_question_value
                          )}?`}</li>
                        ) : null}

                        {question.screening_question_group === "industry" ? (
                          <li>{`How many years of ${titleCase(
                            question.screening_question_value
                          )} experience do you currently have?`}</li>
                        ) : null}

                        {question.screening_question_group === "custom" && (
                          <li>{question.screening_question_value}</li>
                        )}
                      </ul>
                    ))}
                  </div>
                )}

                {/* applications */}
                <AppliedCard id={data?.job.id} />
              </div>
            </div>

            {/* buttons */}
            <div className="flex flex-col md:flex-row md:justify-between items-center mt-12">
              <Link to={`/jobs/${data?.job.id}/applications`}>
                <button className="bg-blueBtn text-white text-sm py-1.5 px-4 rounded-2xl mt-2.5 flex justify-between">
                  View Applications
                </button>
              </Link>

              <button
                onClick={() => setIsOpen(true)}
                className="bg-blueBtn text-white text-sm py-1.5 px-4 rounded-2xl mt-2.5 flex justify-between"
              >
                Edit Job
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen -mt-16">
          <span className="flex text-black space-x-3 items-center justify-center ">
            <ImSpinner3 size={48} className="animate-spin" />
            <p className="text-3xl">Loading</p>
          </span>
        </div>
      )}
    </AdminLayout>
  )
}

export default JobDetails
