import { createContext, useState } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import AdminDashboard from "./pages/AdminDashboard"
import AdminLogin from "./pages/AdminLogin"
import UserRegistration from "./pages/UserRegistration"
import UserLogin from "./pages/UserLogin"
import ComingSoon from "./pages/ComingSoon"
import CandidateDashboard from "./pages/CandidateDashboard"
import MyProfile from "./pages/MyProfile"
import MyApplications from "./pages/MyApplications"
import MyJobs from "./pages/MyJobs"
import ManageAdmin from "./pages/ManageAdmin"
import MyInterview from "./pages/MyInterview"
import Onboarding from "./pages/Onboarding"
import ManageApplicant from "./pages/ManageApplicant"
import BulkUpload from "./pages/BulkUpload"
import AdminReg from "./pages/AdminReg"
import ErrorPage from "./pages/ErrorPage"
import MyLeave from "./pages/MyLeave"
import ManageLeave from "./pages/ManageLeave/ManageLeave"
import Leave from "./pages/Leave"
import Jobs from "./pages/Jobs"
import AddJob from "./pages/AddJob"
import JobDetails from "./pages/JobDetails"
import AddApplicant from "./pages/AddApplicant"
import JobApplications from "./pages/JobApplications"
import PersistLogin from "./components/PersistLogin"
import RequireAuth from "./components/RequireAuth"
import Layout from "./components/Layout"
import JobDescription from "./pages/JobDescription"
import AppliedJobDescription from "./pages/AppliedJobDescription"
import ApplicantFiles from "./components/ApplicantFiles"
import ApplicantAnswers from "./components/ApplicantAnswers"
import UserLandingPage from "./pages/UserLandingPage"
import FilteredJobsPage from "./pages/FilteredJobsPage"
import HomeJobDescription from "./pages/HomeJobDescription"
import AssessmentQuiz from "./pages/CandidateAssessment/AssessmentQuiz"
import CreateQuestions from "./components/CreateAssessment/CreateQuestions"
import AssessmentPage from "./pages/CandidateAssessment/AssessmentPage"
import AdminProfile from "./pages/AdminProfile"
import VerifyEmail from "./pages/VerifyEmail"
import VerifyUser from "./pages/VerifyUser"
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"
import AdminSettings from "./pages/AdminSettings"
import UserSettings from "./pages/UserSettings"
import VerifyAdminRole from "./components/VerifyAdminRole"
import VerifyUserRole from "./components/VerifyUserRole"
import ManageHome from "./components/ManageAssessment/ManageHome"
import AdminManageAssessment from "./pages/AdminManageAssessment/AdminManageAssessment"
import AssessmentDetails from "./pages/AdminManageAssessment/AssessmentDetails"
import UserFeedback from "./pages/UserFeedback"
import CandidateProfile from "./pages/CandidateProfile"
import CreateJobAlertPage from "./components/CreateJobAlertPage"
import RequireUser from "./layout/RequireUser"
import RequireSuperAdmin from "./layout/RequireSuperAdmin"
import RequireAdmin from "./layout/RequireAdmin"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import ShareLink from "./components/shareJobProfile/ShareLink"
import TagManager from "react-gtm-module"
import { HelmetProvider } from "react-helmet-async"

export const SetPopupContext = createContext()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
})

const tagManagerArgs = {
  gtmId: "G-H2EQ9CPGV1",
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Routes>
          <Route element={<Layout />}>
            {/**public routes */}

            <Route path="/" element={<UserLandingPage />} />
            <Route
              path="/jobs/collections/:category/:key"
              element={<FilteredJobsPage />}
            />

            <Route
              path="/home-job-description/:id/:title"
              element={<HomeJobDescription />}
            />
            <Route path="/user-login" element={<UserLogin />} />
            <Route path="/user-settings" element={<UserSettings />} />
            <Route path="/user-feedback" element={<UserFeedback />} />
            <Route path="/registration" element={<UserRegistration />} />
            <Route path="/job-alert" element={<CreateJobAlertPage />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin-registration" element={<AdminReg />} />

            <Route path="/leave" element={<Leave />} />
            <Route path="/my-leave" element={<MyLeave />} />
            <Route path="/manage-leave" element={<ManageLeave />} />

            <Route element={<VerifyAdminRole />}>
              <Route
                path="/verify-email/:verificationCode"
                element={<VerifyEmail />}
              />
            </Route>
            <Route element={<VerifyUserRole />}>
              <Route
                path="/verify-user/:verificationCode"
                element={<VerifyUser />}
              />
            </Route>

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:code" element={<ResetPassword />} />

            {/**applicant routes */}
            <Route element={<PersistLogin allowedRole={["applicant"]} />}>
              <Route element={<RequireUser allowedRole={["applicant"]} />}>
                <Route path="/onboarding" element={<Onboarding />} />
                <Route
                  path="/candidate-dashboard"
                  element={<CandidateDashboard />}
                />
                <Route path="/my-profile" element={<CandidateProfile />} />
                <Route path="/my-applications" element={<MyApplications />} />
                <Route path="/my-jobs" element={<MyJobs />} />
                <Route path="/my-interview" element={<MyInterview />} />
                <Route path="/assessment-page" element={<AssessmentPage />} />
                <Route path="/assessment-quiz" element={<AssessmentQuiz />} />
                <Route
                  path="/job-description/:id/:title"
                  element={<JobDescription />}
                />
                <Route
                  path="/applied-job-description/:id/:title"
                  element={<AppliedJobDescription />}
                />
              </Route>
            </Route>

            {/**super admin routes */}
            <Route element={<PersistLogin allowedRole={["superAdmin"]} />}>
              <Route
                element={<RequireSuperAdmin allowedRole={["superAdmin"]} />}
              >
                <Route path="/manage-admin" element={<ManageAdmin />} />
                <Route path="/admin-profile" element={<AdminProfile />} />
              </Route>
            </Route>

            {/**admin routes */}
            <Route
              element={<PersistLogin allowedRole={["admin", "superAdmin"]} />}
            >
              <Route
                element={<RequireAdmin allowedRole={["admin", "superAdmin"]} />}
              >
                <Route path="/admin-dashboard" element={<AdminDashboard />} />

                <Route path="/applicants/upload" element={<BulkUpload />} />
                <Route path="/applicants" element={<ManageApplicant />} />
                <Route path="/applicants/add" element={<AddApplicant />} />
                <Route path="/assessments" element={<ManageHome />} />
                <Route path="/assessment/:id" element={<AssessmentDetails />} />
                <Route
                  path="/admin-manage"
                  element={<AdminManageAssessment />}
                />

                <Route path="/jobs" element={<Jobs />} />
                <Route path="/assessment" element={<CreateQuestions />} />
                {/* <Route path="/mailer" element={<Mailer />} /> */}
                <Route path="/jobs/:id/:title" element={<JobDetails />} />

                <Route path="/share-link" element={<ShareLink />} />
                <Route path="/jobs/add" element={<AddJob />} />
                <Route path="/admin-settings" element={<AdminSettings />} />
                <Route
                  path="/jobs/:id/applications"
                  element={<JobApplications />}
                />
                <Route
                  path="/jobs/:id/applications/files"
                  element={<ApplicantFiles />}
                />
                <Route
                  path="/jobs/:id/applications/files/answers"
                  element={<ApplicantAnswers />}
                />
              </Route>
            </Route>

            <Route path="/coming-soon" element={<ComingSoon />} />

            {/**catch all */}
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </HelmetProvider>

      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  )
}

export default App
