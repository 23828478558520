import { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import AdminLayout from "../components/AdminLayout"
import AdminHeader from "../components/AdminHeader"
import axios from "../api/axios"
import useAuth from "../hooks/useAuth"
import AppliedJobCard from "../components/AppliedJobCard"
import ApplicantCard from "./ApplicantCard"
import { timestampToDate, titleCase } from "../utils/helpers"

const ApplicantFiles = () => {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState(-1)
  let params = useParams()
  const { auth } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
      const res = await axios
        .get(`applications/applicants/${params.id}`)
        .catch(error => {
          console.error(error?.res)
          // navigate("/admin-login", { state: { from: location }, replace: true })
        })
      if (res) {
        isMounted && setData(res?.data?.data)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])
  return (
    <AdminLayout page="applications">
      <AdminHeader page="admin-dashboard" />
      <div className="px-12 py-6 space-y-4">
        <h3 className="text-md text-center text-gray-500">Applicant Answers</h3>
        <div>
          {data?.applicant_answers?.map((app, index) => {
            return (
              <div
                key={app?.id}
                className="
                    bg-white py-6 px-4 rounded-md shadow"
              >
                <div className="flex items-center justify-between px-4"></div>
                <div className="text-sm text-gray-600 ">{app?.id}</div>

                <div className="text-sm text-gray-600 mb-3 justify-between px-2">
                  {app?.answers}
                </div>

                <div className="text-sm text-gray-600 mb-3 justify-between px-2">
                  {app?.github}
                </div>

                <div className="text-sm text-gray-600 mb-3 justify-between px-2">
                  {app?.linkedin}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </AdminLayout>
  )
}

export default ApplicantFiles
