import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { GrFormView } from "react-icons/gr";
import "../styles/ApplicantList.css";
import Modal from "./Modal";
import ApplicantCard from "./ApplicantCard";
import { timestampToDate, titleCase } from "../utils/helpers";
import EditApplicant from "./editApplicant";
import ViewResumeModal from "./ViewResumeModal";

const ApplicantList = ({ data, search }) => {
  const [selected, setSelected] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [applicantData, setApplicantData] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleEdit = (item, index) => {
    setOpenModal(true);
    setApplicantData(item);
  };

  const alternatingStyles = [
    "bg-white text-slate-700",
    "bg-slate-300 text-white",
  ];
  const navigate = useNavigate();

  return (
    <div className="">
      {openModal && (
        // <Modal
        //   setOpenModal={setOpenModal}
        //   applicantData={applicantData}
        //   setApplicantData={setApplicantData}
        // />
        <EditApplicant
          openModal={openModal}
          setOpenModal={setOpenModal}
          applicantData={applicantData}
        />
      )}

      {showModal && (
        <ViewResumeModal
          showModal={showModal}
          selected={selected}
          data={data}
          setShowModal={setShowModal}
        />
      )}

      <table className="border-none w-full">
        <thead>
          <tr className="bg-slate-600 w-full">
            <th className="w-1/12"></th>
            <th className="w-2/12">NAME</th>
            <th className="hideprop2 w-2/12">EMAIL</th>
            <th className="hideprop2 w-1/12">COUNTRY</th>
            <th className="hideprop w-1/12">PHONE</th>
            <th className="hideprop w-2/12">POSITION APPLIED</th>
            <th className="hideprop w-1/12">DATE SUBMITTED</th>
            <th className="w-1/12">STATUS</th>
          </tr>
        </thead>
        <tbody className="w-full relative">
          {data?.length > 0 &&
            search(data).map((item, index) => {
              return (
                <tr
                  key={item.applicant.id}
                  className={`${
                    alternatingStyles[index % alternatingStyles.length]
                  } py-4 relative`}
                >
                  <td className="flex items-center justify-between px-4">
                    {/* <span className="w-1/2">{appList.id}</span> */}
                    <FiEdit onClick={() => handleEdit(item, index)} />

                    <GrFormView
                      size={24}
                      onClick={() => {
                        setSelected(index);
                        setShowModal(true)
                      }}
                    />
                  </td>
                  <td>
                    {`${titleCase(item.applicant.first_name)} ${titleCase(
                      item.applicant.last_name
                    )}`}
                  </td>
                  <td className="hideprop2">{item.applicant.email}</td>
                  <td className="hideprop2">
                    {titleCase(item.applicant.country)}
                  </td>
                  <td className="hideprop">{item.applicant.phone_number}</td>

                  <td className="hideprop">
                    {titleCase(item.application.job_role)}
                  </td>
                  <td className="hideprop">
                    {timestampToDate(item.applicant.updated_at)}
                  </td>
                  <td className="">{titleCase(item.application.status)}</td>
                </tr>
              );
            })}
          {/* <ApplicantCard
            data={data}
            selected={selected}
            setSelected={setSelected}
          /> */}
        </tbody>
      </table>
    </div>
  );
};

export default ApplicantList;