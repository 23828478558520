import parse from "html-react-parser";
import { titleCase } from "../../utils/helpers";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const Preview = ({
  formik,
  screeningQuestions,
  jobDescription,
  currentStep,
  setCurrentStep,
}) => {
  return (
    <div className="py-3 text-black">
      <div className="space-y-6">
        <div>
          <p className="text-2xl font-semibold">{`${formik.values.job_title} at ${formik.values.company_name}`}</p>
          <p className="text-sm text-gray-400">{`${formik.values.job_city}, ${formik.values.job_country} (${formik.values.employment_type})`}</p>
        </div>

        {jobDescription && (
          <div className="list-disc">{parse(jobDescription)}</div>
        )}

        {formik.values.min_salary_range && formik.values.max_salary_range && (
          <div>
            <p className="text-base underline font-bold">Salary Range</p>
            <p>{`${formik.values.currency}${formik.values.min_salary_range} - ${formik.values.currency}${formik.values.max_salary_range}`}</p>
          </div>
        )}

        <div>
          <p className="text-base underline font-bold">Application Deadline</p>
          <p>{formik.values.application_deadline}</p>
        </div>

        <div>
          <span className="text-base underline font-bold">
            Screening Questions
          </span>
          {screeningQuestions.map((question, index) => (
            <div key={index} className="mb-2">
              {question.screening_question_group === "tool or technology" && (
                <p>{`How many years of experience do you have using ${titleCase(
                  question.screening_question_value
                )}?`}</p>
              )}

              {question.screening_question_group === "language" && (
                <p>{`What is your level of proficiency in ${titleCase(
                  question.screening_question_value
                )}?`}</p>
              )}

              {question.screening_question_group === "industry" && (
                <p>{`How many years of ${titleCase(
                  question.screening_question_value
                )} experience do you currently have?`}</p>
              )}

              {question.screening_question_group === "custom" && (
                <p>{titleCase(question.screening_question_value)}</p>
              )}

              <p>
                Ideal Answer: {titleCase(question.screening_required_answer)}
              </p>
              <p>
                Must-have Qualification:{" "}
                {question.screening_must_have_qualification ? "Yes" : "No"}
              </p>
            </div>
          ))}
        </div>
      </div>

      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={(e) => {
            formik.handleSubmit(e);
          }}
        >
          SUBMIT
        </Button>
      </Box>
    </div>
  );
};

export default Preview;
