import SidebarContextProvider from "../contexts/SidebarContext"
import Sidebar from "./Sidebar"

const AdminLayout = ({ children, page }) => {
  return (
    <SidebarContextProvider>
      <div className="flex w-full min-h-screen bg-slate-50 font-roboto relative">
        <Sidebar page={page} />
        <div className="lg:w-4/5 w-full ml-auto relative">{children}</div>
      </div>
    </SidebarContextProvider>
  )
}

export default AdminLayout
