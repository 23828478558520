// const Comment = ({ author, content }) => {
//     return (
//       <div>
//         <h3>{author}</h3>
//         <p>{content}</p>
//       </div>
//     );
//   };
  
//   export default Comment;

import  {AiOutlineRetweet}  from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FaUser } from "react-icons/fa";
import './comment.css'
import { axiosPrivate } from '../../api/axios';

const Comment = ({ id, text, name, onDelete, onReply, selectedComment }) => {
  // const isSelected = id === selectedComment;

  // const handleCommentDelete = async () => {
  //   try{
  //     await axiosPrivate.delete(`jobs/comment/${id}`);
  //     onDelete(id);
  //   } catch (error) {
  //     console.error('Error deleting comment', error);
  //   }
  // }


  return (
    <div className="block mx-4 p-2.5 flex items-center px-3 py-2 rounded-lg  w-full">
      
      <div className="flex flex-row gap-3">        
      <h4 className="mb-3 text-gray-500 text-sm font-bold dark:text-gray-400">{name}:</h4>
      {/* <div className={isSelected ? 'selected-comment' : ''}> */}
      <p className="mb-3 text-gray-500 text-sm font-bold dark:text-gray-400">{text}</p>
      {/* </div> */}
      </div>
  
      <div className='flex flex-row'>  
      <button 
          className="text-black text-sm mt-2 py-1.5 px-1 rounded-1xl"
       onClick={() => onDelete(id)}>
        <RiDeleteBinLine />
       </button>
        {/* <button className='text-black text-sm mt-2 py-1.5 rounded-1xl' 
        onClick={() => onReply(id)}>
          <AiOutlineRetweet/>
        </button> */}
        </div>
    </div>
  );
};
export default Comment;