import React, { useState, useEffect } from "react"
import { FaUser } from "react-icons/fa"
import Comment from "./Comment"
import axios from "../../api/axios"
import Swal from "sweetalert2"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { comment } from "postcss"
import { useParams } from "react-router"
// import io from 'socket.io-client';

const CommentSection = () => {
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState("")
  const [selectedComment, setSelectedComment] = useState(null)
  let params = useParams()

  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  // const socket = io('/')

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }
    // const storedComment = localStorage.getItem('comment');
    // if (storedComment){
    //   setNewComment(storedComment);
    // }
    const getJobComment = async () => {
      const res = await axiosPrivate
        .get(`jobs/comments/${params.id}`, request)
        .catch(error => {
          console.error(error?.res)
        })
      if (res?.data) {
        isMounted && setComments(res?.data?.data)
      }
    }
    getJobComment()

    //   socket.on('commentCreated', (newComment) => {
    // setComments((prevComments) => [...prevComments, newComment]);
    //   });

    // socket.on('commentDeleted', (deletedCommentId) => {
    //   setComments((prevComments) =>
    //   prevComments.filter((comment) => comment.id !== deletedCommentId));
    // });
    return () => {
      isMounted = false
      controller.abort()
      // socket.disconnect();
    }
  }, [])

  // Add more state variables as needed
  const handleCommentSubmit = async e => {
    e.preventDefault()

    const res = await axiosPrivate
      .post("jobs/comment", {
        company_id: auth?.companyId,
        job_id: params?.id,
        admin_id: auth?.id,
        name: auth?.firstName,
        comment: newComment,
      })
      .catch(error => {
        console.error(error?.response)
        if (error?.response) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
            position: "top",
            timer: 2000,
            showConfirmButton: false,
          })
        }
      })
    if (res) {
      if (res?.status === 201) {
        Swal.fire({
          toast: true,
          icon: "success",
          title: "Job comment Created",
          text: res?.data?.message,
          position: "top",
          timer: 2000,
          showConfirmBottom: false,
        })
      }
      setComments(comments)
      // localStorage.setItem('comment', newComment)
      setNewComment("")
    }
  }

  const handleCommentDelete = async id => {
    const updatedComment = comments.filter(comment => comment.id !== id)
    setComments(updatedComment)
    const res = await axiosPrivate.delete(`jobs/comment/${id}`).catch(error => {
      console.error(error?.res)
    })
    if (res?.data) {
      setComments(comments.filter((_, index) => index !== id))
      setComments(res?.data?.data)
    }
  }

  const handleCommentReply = id => {
    setSelectedComment(id)
  }

  console.log({ comments })
  return (
    <div>
      <form onSubmit={handleCommentSubmit}>
        {/* <input type="text" name="commentInput" placeholder="Enter your comment" />
      <button type="submit">Submit</button> */}
        <div className="flex-col">
          <div class="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700 w-full">
            <input
              type="text"
              name="commentInput"
              className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your comment..."
              value={newComment}
              onChange={e => {
                setNewComment(e.target.value)
              }}
            />
            <button
              type="submit"
              className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                className="w-6 h-6 rotate-90"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
              </svg>
              <span className="sr-only">Send message</span>
            </button>
          </div>
          <div>
            {newComment && (
              <p className="mb-3 text-gray-500 text-sm font-bold dark:text-gray-400">
                {newComment}
              </p>
            )}
          </div>
        </div>
      </form>
      {comments.map((comment, index) => (
        <Comment
          key={index}
          id={comment.id}
          name={comment.name}
          text={comment.comment}
          onDelete={handleCommentDelete}
          onReply={handleCommentReply}
          selectedComment={selectedComment}
        />
      ))}
    </div>
  )
}

export default CommentSection
